/* Contenedor de la lista de proyectos */
.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 8px;
  padding: 8px;
}

/* Tarjeta de proyecto */
.project-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Imagen del proyecto */
.project-card img {
  width: 100%;
  height: auto;
  display: block;
}

/* Nombre del proyecto */
.project-card h3 {
  margin: 8px;
  font-size: 1.2em;
  color: #333;
}

/* Contenedor de la disposición */
.layout {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 5px;
  padding: 5px;
  margin-right: 8px;
}

/* Lado izquierdo */
.left-side {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Lado derecho */
.right-side {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Tarjeta */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Estilo para el iframe dentro de la tarjeta */
.card iframe {
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 8px;
  flex-grow: 1;
}

.frame-row {
  height: 300px;
}

.type-card {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: transparent;
  max-height: 40px;
}

.type-card.header {
  background-color: #e9e9e9;
  font-weight: bold;
}

.type-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.selected {
  background-color: #ccc;
}

.header-row {
  display: grid;
  grid-template-columns: 20% 40% 20% 20%;
}

.lead-card {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: transparent;
  max-height: 40px;
}

.lead-card.header {
  background-color: #e9e9e9;
  font-weight: bold;
}

.lead-card .email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quote-card {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 2fr 1fr;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: transparent;
  max-height: 40px;
}

.quote-card div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 2px;
}

.quote-card.header {
  background-color: #e9e9e9;
  font-weight: bold;
}

.quote-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-title {
  max-height: 24px;
}

.project-name {
  font-weight: bold;
  text-align: center;
  border: 1px solid #ddd;
}

.status {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 10px;
}

.available {
  background-color: hsla(120, 91%, 70%, 0.7);
}

.sold {
  background-color: hsla(0, 91%, 70%, 0.7);
}

.optioned {
  background-color: hsla(209, 91%, 70%, 0.4);
}

.reserved {
  background-color: hsla(32, 91%, 70%, 0.7);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #ddd;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #333;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}