/* src/App.css */
.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* Cuando el sidebar está activo */
.app-with-sidebar {
  display: flex;
  flex-direction: row; /* Alinea el sidebar y el contenido en una fila */
}

.sidebar {
  width: 250px;
  background-color: #4D4D4D;
  color: white;
  padding-top: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.sidebar h2 {
  color: white;
  padding: 15px;
}

/* Estilos para la lista del menú */
.sidebar ul {
  list-style: none;  /* Elimina los puntos de lista */
  padding: 0;
}

.sidebar ul li {
  padding: 15px;
}

.sidebar ul li a, .sidebar ul li button {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
}

.sidebar ul li button {
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
  font-size: 16px;
}

.sidebar-logo {
  width: 200px; 
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  padding-left: 15px;
}

.main-content {
  flex-grow: 1;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Ocupa toda la pantalla */
  font-size: 24px;
  color: #333;
  background-color: #f5f5f5;
}

/* Aplicar margen solo cuando hay sidebar */
.app-with-sidebar .main-content {
  padding-left: 20px;
  margin-left: 250px;  /* Deja espacio para el sidebar */
}

.error-message {
  border: 1px solid red;
  background-color: #f8d7da;
  padding: 10px;
  color: #721c24;
}

/* Contenedor de las pestañas */
.tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 16px;
}

/* Botón de pestaña */
.tabs button {
  background-color: #f9f9f9;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 10px 20px;
  cursor: pointer;
  transition: border-bottom 0.3s, background-color 0.3s;
  font-size: 16px;
  color: #333;
}

.tabs button:hover {
  background-color: #e9e9e9;
}

.tabs button.active {
  border-bottom: 2px solid #007bff;
  background-color: #fff;
  font-weight: bold;
  color: #007bff;
}

/* Contenido de la pestaña */
.tab-content-1 {
  padding: 16px;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 180px;
  overflow-y: auto;
}

.tab-content-2 {
  padding: 16px;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 800px;
  overflow-y: auto;
}

.status-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  margin-bottom: 2px;
}

.status-available {
  background-color: hsla(120, 91%, 70%, 0.7);
  color: white;
}

.status-sold {
  background-color: hsla(0, 91%, 70%, 0.7);
  color: white;
}

.status-reserved {
  background-color: hsla(32, 91%, 70%, 0.7);
  color: white;
}

.status-optioned {
  background-color: hsla(209, 91%, 70%, 0.4);
  color: white;
}